import {
  AbsoluteCenter,
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import {
  getLenderPerformanceSummaryApi,
  getLenderTransactionsApi,
} from '../api/lenderApi';
import { pluralize } from '../helpers/languageHelper';
import { Currency, Percentage } from './Number';
import WhiteBox from './WhiteBox';
import { FundingIcon } from './icons/FundingIcon';
import { GreenArrowIcon } from './icons/GreenArrowIcon';
import { ServiceFeesIcon } from './icons/ServiceFeesIcon';
// import '../assets/scss/CustomerPerformance.scss';

function StatsTitle(props) {
  return (
    <Box
      mb="5"
      color={'gray.main'}
      fontSize={{ base: '14px', lg: '16px' }}
      fontWeight={500}
      textTransform={'uppercase'}
      {...props}
    >
      {props.children}
    </Box>
  );
}

function StatsValue(props) {
  return (
    <Box
      color={'black.main'}
      fontSize={{ base: '18px', lg: '24px' }}
      fontWeight={700}
      lineHeight={7}
      {...props}
    >
      {props.children}
    </Box>
  );
}

function SmallGrayText(props) {
  return (
    <Text
      fontSize={'12px'}
      fontWeight="400"
      color="gray.main"
      lineHeight={'5'}
      {...props}
    >
      {props.children}
    </Text>
  );
}

function NextPaymentBox({ date, amount, title, icon }) {
  let daysLeftText = '';
  let diff = moment().diff(moment(date), 'days');

  const daysText = pluralize({
    count: Math.abs(diff),
    singular: 'day',
    plural: 'days',
  });

  if (diff != 0) {
    daysLeftText = diff > 0 ? `(${daysText} ago)` : `(in ${daysText})`;
  } else {
    daysLeftText = '(Today)';
  }

  return (
    <WhiteBox
      gap="4"
      rounded={20}
      py={{ base: '4', lg: '30px' }}
      px={{ base: '4', lg: '6' }}
    >
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'start'}
      >
        <Box>
          <StatsTitle>{title}</StatsTitle>
          <Flex flexWrap={'wrap'} rowGap="3">
            <StatsValue display={'flex'} alignItems={'end'}>
              <Moment format="l">{date}</Moment>&nbsp;
              <SmallGrayText>{daysLeftText}</SmallGrayText>
            </StatsValue>
            <StatsValue ml={5}>
              <Currency value={amount} />
            </StatsValue>
          </Flex>
        </Box>
        <Icon as={icon} w={8} />
      </Box>
    </WhiteBox>
  );
}

function Transactions({ transactions }) {
  return (
    <WhiteBox rounded={20} mt="5" p={0}>
      <Box py={5} pl={6} textTransform={'uppercase'} fontWeight={700}>
        Balance Transactions
      </Box>
      <TableContainer className="transaction-table" overflow={'auto'}>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th>Transaction Type</Th>
              <Th>Amount</Th>
            </Tr>
          </Thead>
          <Tbody>
            {transactions.map((transaction, index) => {
              const isPositive = transaction.amount > 0;
              return (
                <Tr key={`tr${index}`}>
                  <Td>
                    <Moment format="L">{transaction.date}</Moment>
                  </Td>
                  <Td>{transaction.type}</Td>
                  <Td>
                    <Currency value={transaction.amount} />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </WhiteBox>
  );
}

function LenderPerformance({ lenderId }) {
  const [loadingSummary, setLoadingSummary] = useState(true);
  const [loadingTransactions, setLoadingTransactions] = useState(true);
  const [summaryData, setSummaryData] = useState(null);
  const [transactionsData, setTransactionsData] = useState(null);

  useEffect(() => {
    loadSummaryData();
    loadTransactionsData();
  }, []);

  const loadSummaryData = () => {
    getLenderPerformanceSummaryApi(lenderId)
      .then((data) => {
        setSummaryData(data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoadingSummary(false);
      });
  };

  const loadTransactionsData = () => {
    getLenderTransactionsApi(lenderId)
      .then((data) => {
        setTransactionsData(data);
      })
      .catch((error) => {
        // console.log(error);
      })
      .finally(() => {
        setLoadingTransactions(false);
      });
  };

  if (loadingSummary || loadingTransactions) {
    return (
      <AbsoluteCenter>
        <Spinner />
      </AbsoluteCenter>
    );
  }

  if (!summaryData) {
    return (
      <AbsoluteCenter>
        <WhiteBox>
          <Heading size={'l'}>No Data Yet.</Heading>
        </WhiteBox>
      </AbsoluteCenter>
    );
  }

  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)" gap={4} alignItems={'center'}>
        <GridItem colSpan={{ base: '12', lg: '4', xl: '3' }}>
          <WhiteBox
            gap="4"
            rounded={20}
            py={{ base: '4', lg: '30px' }}
            px={{ base: '4', lg: '6' }}
          >
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'start'}
            >
              <Box>
                <StatsTitle>
                  Total funding <br /> to date
                </StatsTitle>
                <StatsValue>
                  <Currency value={summaryData.capital} />
                </StatsValue>
              </Box>
              <Icon as={FundingIcon} w={8} h={8} />
            </Box>
          </WhiteBox>
        </GridItem>
        <GridItem colSpan={{ base: '12', lg: '8', xl: '6' }}>
          <WhiteBox
            bg="white"
            shadow={'box'}
            rounded={20}
            py={{ base: '4', lg: '30px' }}
            px={{ base: '4', lg: '6' }}
          >
            <Box
              display={'flex'}
              flexWrap={{ base: 'wrap', lg: 'nowrap' }}
              alignItems={'center'}
              gap={{ base: '8', lg: '60px' }}
            >
              <Box w={'180px'}>
                <StatsTitle>
                  Unused Funds <br /> to date
                </StatsTitle>
                <StatsValue>
                  <Currency value={summaryData.unusedFunds} />
                </StatsValue>
              </Box>
              <Box width="100%">
                <Flex flexWrap={'wrap'} gridGap={'30px'} rowGap={3}>
                  <Flex alignItems={'center'} gap={'10px'}>
                    <Box w={5} h={5} rounded={'full'} bg={'pink.main'}></Box>
                    <Box
                      color="gray.main"
                      fontSize={{ base: '12px', lg: '14px' }}
                    >
                      Used funds (
                      <Percentage value={summaryData.principalPct} />)
                    </Box>
                  </Flex>
                  <Flex alignItems={'center'} gap={'10px'}>
                    <Box w={5} h={5} rounded={'full'} bg={'green.200'}></Box>
                    <Box
                      color="gray.main"
                      fontSize={{ base: '12px', lg: '14px' }}
                    >
                      Unused funds (
                      <Percentage value={summaryData.unusedFundsPct} />)
                    </Box>
                  </Flex>
                </Flex>
                <Box rounded={'full'} display={'flex'} mt="5">
                  <Box
                    bg={'pink.main'}
                    w={`${summaryData.principalPct * 100}%`}
                    h="5"
                    borderLeftRadius="full"
                  ></Box>
                  <Box
                    bg={'green.200'}
                    w={`${summaryData.unusedFundsPct * 100}%`}
                    h="5"
                    borderRightRadius="full"
                  ></Box>
                </Box>
              </Box>
            </Box>
          </WhiteBox>
        </GridItem>
        <GridItem colSpan={{ base: '12', lg: '8', xl: '3' }}>
          <WhiteBox
            bg="white"
            shadow={'box'}
            rounded={20}
            py={{ base: '4', lg: '4' }}
            px={{ base: '4', lg: '6' }}
          >
            <Box>
              <Flex gap={4}>
                <StatsTitle>
                  SOFR M1{' '}
                  <SmallGrayText>
                    <Moment format="l">{summaryData.sofrDate}</Moment>
                  </SmallGrayText>
                </StatsTitle>
                <StatsValue>
                  <Percentage
                    value={summaryData.sofrPct}
                    maximumFractionDigits={2}
                  />
                </StatsValue>
              </Flex>
              <Grid templateColumns="repeat(2, 1fr)">
                <GridItem>
                  <StatsTitle mb={1}>Fee</StatsTitle>
                  <StatsValue>
                    <Percentage
                      value={summaryData.capitalInterestPct}
                      maximumFractionDigits={2}
                    />
                  </StatsValue>
                </GridItem>
                <GridItem>
                  <StatsTitle mb={1}>Interest</StatsTitle>
                  <StatsValue>
                    <Percentage
                      value={summaryData.principalInterestPct}
                      maximumFractionDigits={2}
                    />
                  </StatsValue>
                </GridItem>
              </Grid>
            </Box>
          </WhiteBox>
        </GridItem>
        <GridItem colSpan={{ base: '12', lg: '2', xl: '2' }}></GridItem>
        <GridItem colSpan={{ base: '12', lg: '4', xl: '4' }}>
          <NextPaymentBox
            title={'Next Payment'}
            icon={GreenArrowIcon}
            date={summaryData.nextPaymentDate}
            amount={summaryData.nextPaymentAmount}
          />
        </GridItem>
        <GridItem colSpan={{ base: '12', lg: '4', xl: '4' }}>
          <WhiteBox
            gap="4"
            rounded={20}
            py={{ base: '4', lg: '30px' }}
            px={{ base: '4', lg: '6' }}
          >
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'start'}
            >
              <Box>
                <StatsTitle>Accrued Interest</StatsTitle>
                <StatsValue display={'flex'} alignItems={'end'}>
                  <Currency
                    value={summaryData.accruedInterest}
                    maximumFractionDigits={2}
                  />
                </StatsValue>
              </Box>
              <Icon as={ServiceFeesIcon} w={10} h={8} />
            </Box>
          </WhiteBox>
        </GridItem>
        <GridItem colSpan={{ base: '12', lg: '2', xl: '2' }}></GridItem>
      </Grid>
      <Box mt={20}>
        <Transactions transactions={transactionsData} />
      </Box>
    </>
  );
}

export default LenderPerformance;
